
import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5'

import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';

export default {
  plugins: {
    Notify,
    Loading
  },
  extras: [
    'material-icons',
    'fontawesome-v5'
  ],
  framework: {
    iconSet: 'fontawesome-v5'
  }
}